import React from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import { useTranslation } from "react-i18next"
import { MEDIA_MAX_MEDIUM, MEDIA_MIN_LARGE } from "../../constants"
import Button from "../ui/Button"
import { getColorName } from "../../helpers/getColorName"

const Container = styled.div`
  margin: 0;
  position: relative;
  z-index: 9;

  ${MEDIA_MIN_LARGE} {
    margin: 0 2.5rem 0 0;
  }
`

const OpenFilterButton = styled(Button)`
  height: auto;
  padding: 2.5rem;
  width: 100%;
  border-top: 0.1rem solid;
  justify-content: flex-start;
  color: ${(p) => p.theme.colors.black};
  border-color: ${(p) => p.theme.colors.lightBorder};

  ${MEDIA_MIN_LARGE} {
    font-size: 1.2rem;
    padding: 0.5rem 0;
    width: auto;
    border: none;
  }
`

const Icon = styled.div`
  padding: 0;
  transform: rotate(${(p) => (p.open ? "180deg" : "0deg")});
  transition: transform 0.2s;
  color: ${(p) => p.theme.colors.black};
`

const CurrentFilters = styled.div`
  color: ${(p) => p.theme.colors.orange};
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

const PopOutOptionsList = styled.ul`
  padding: 0 2.5rem 1rem;
  margin: 0;

  ${MEDIA_MIN_LARGE} {
    position: absolute;
    top: 3.5rem;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    margin: 0 0 1rem 0;
    background: ${(p) => p.theme.colors.background};
    width: 20rem;
    z-index: 10;
    font-size: 1.2rem;
    border-top: solid;
    border-color: ${(p) => p.theme.colors.orange};
    box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 10%);

    &:before {
      bottom: 100%;
      border: solid transparent;
      left: 3rem;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-bottom-color: ${(p) => p.theme.colors.orange};
      border-width: 0.8rem;
    }
  }
`

const PopOutListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  pointer-events: ${(p) => (p.active ? "all" : "none")};
  padding: 1rem 0;

  label {
    color: ${(p) =>
      p.active ? p.theme.colors.black : p.theme.colors.lightGrey};

    &:before {
      opacity: ${(p) => (p.active ? "1" : "0.4")};
    }
  }

  ${MEDIA_MIN_LARGE} {
    padding: 0.5rem 0;
  }
`

const PopOutListLabel = styled.label`
  position: relative;
  padding: 0 0 0.7rem 2.8rem;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  color: ${(p) => p.theme.colors.black};

  &:before {
    left: -0.3rem;
    border: solid;
    content: " ";
    height: 1.9rem;
    width: 1.9rem;
    position: absolute;
    pointer-events: none;
    border-color: ${(p) => p.theme.colors.darkBorder};
    border-width: 0.1rem;
    border-radius: 50%;
  }

  ${(p) =>
    p.checked &&
    css`
      &:after {
        left: -0.3rem;
        margin: 0.4rem;
        border: solid;
        background: ${(p) => p.theme.colors.orange};
        content: " ";
        height: 1.3rem;
        width: 1.3rem;
        position: absolute;
        pointer-events: none;
        border-width: 0;
        border-radius: 50%;
      }
    `}
`

const PopOutCloseContainer = styled.div`
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  cursor: pointer;

  ${MEDIA_MAX_MEDIUM} {
    display: none;
  }
`

const filterChange = (
  e,
  currentFilters,
  setFilterState,
  pageSlug,
  filterType
) => {
  if (currentFilters.find((filter) => filter === e.target.value)) {
    const updatedValue = [...currentFilters]
    updatedValue.splice(currentFilters.indexOf(e.target.value), 1)
    setFilterState((prev) => ({
      ...prev,
      state: updatedValue
    }))
  } else {
    // Redirect and save filter state when changeing gender value in coll.page
    if (filterType === "gender" && pageSlug) {
      navigate("/filter/", {
        state: { gender: e.target.value }
      })
    }
    const updatedValue = [...currentFilters]
    updatedValue.push(e.target.value)
    setFilterState((prev) => ({
      ...prev,
      state: updatedValue
    }))
  }
}

const FilterList = ({
  filterType,
  filterState: { default: defaultFilter, current: currentFilter, state },
  setFilterState,
  focusedMenu,
  setFocusedMenu,
  pageSlug
}) => {
  const { t } = useTranslation("translations")

  return (
    <div>
      <Container>
        <OpenFilterButton
          onClick={() => {
            setFocusedMenu(filterType !== focusedMenu ? filterType : null)
          }}
        >
          {filterType.toUpperCase()}
          <CurrentFilters>
            {" "}
            {state.length > 1
              ? `(${t("Multiple")})`
              : filterType === "color"
              ? getColorName(state[0])
              : state[0]}{" "}
          </CurrentFilters>
          <Icon
            open={focusedMenu === filterType}
            className="fal fa-angle-down"
          ></Icon>
        </OpenFilterButton>
        {focusedMenu === filterType &&
          (defaultFilter?.length > 0 ? (
            <PopOutOptionsList>
              {defaultFilter.map((option) => {
                return (
                  <PopOutListItem
                    key={uuidv4()}
                    active={currentFilter?.includes(option)}
                  >
                    <input
                      type="checkbox"
                      id={option}
                      name={option}
                      value={option}
                      checked={state?.find((filter) => filter === option)}
                      onChange={(e) =>
                        filterChange(
                          e,
                          state,
                          setFilterState,
                          pageSlug,
                          filterType
                        )
                      }
                    />
                    <PopOutListLabel
                      for={option}
                      checked={state?.find((filter) => filter === option)}
                    >
                      {filterType === "color" ? getColorName(option) : option}
                    </PopOutListLabel>
                  </PopOutListItem>
                )
              })}
            </PopOutOptionsList>
          ) : (
            <PopOutOptionsList>{t("No available options")}</PopOutOptionsList>
          ))}
      </Container>
      {focusedMenu === filterType && (
        <PopOutCloseContainer onClick={() => setFocusedMenu(null)} />
      )}
    </div>
  )
}

export default FilterList
