import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import { MEDIA_MAX_MEDIUM, MEDIA_MIN_LARGE } from "../../constants"
import { Helmet } from "react-helmet"
import { BrinkContext } from "../context/BrinkContext"
import FilterList from "./FilterList"
import Button from "../ui/Button"
import ProductGrid from "../widgets/ProductGrid"
import { fetchStock } from "../../helpers/fetchStock"
import * as events from "../context/utils/events"
import GridWidget from "../widgets/GridWidget"
import ReactPlayer from "react-player"


const Container = styled.div`
  width: 100%;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100% !important;
  padding-bottom: 5.75rem;

  video{
    object-fit: cover;
  }

`

const Image = styled.img`
width: 100%;
  height: auto;
`

const Video = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3rem 1rem 0;
  object-fit: cover;
`

const FilterContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: all 0.3s;
  background: ${(p) => p.theme.colors.background};
  transform: translate(${(p) => (p.open ? "0, 0" : "100%, 0")});
  width: 100%;
  max-width: 45rem;
  z-index: 10004;
  overflow-y: auto;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightBorder};

  ${MEDIA_MIN_LARGE} {
    display: flex;
    max-width: none;
    align-items: center;
    position: relative;
    height: 7rem;
    background: none;
    transform: none;
    overflow-y: initial;
    z-index: auto;
  }
`

const TopBarMobile = styled.div`
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const FilterListContainer = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  margin: 0 0 0 1rem;

  ${MEDIA_MIN_LARGE} {
    border: none;
    display: flex;
  }
`

const ButtonsContainer = styled.div`
  ${MEDIA_MAX_MEDIUM} {
    display: flex;
    padding: 0 1.5rem;
  }
`

const ClearButton = styled(Button)`
  width: 50%;
  height: 4rem;
  font-size: 1.2rem;
  border: 0.1rem solid black;
  margin: 2.5rem 1rem;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  padding-top: 0.1rem;

  ${MEDIA_MIN_LARGE} {
    margin: 2rem 2rem 2rem 1rem;
    height: 3rem;
  }
`

const DoneButton = styled(ClearButton)`
  background: ${(p) => p.theme.colors.primary};
  border: 0.1rem solid ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const OpenMobileFilterButton = styled(Button)`
  width: 12rem;
  height: 3rem;
  font-size: 1.3rem;
  border: none;
  margin: 1.5rem 0 0 0;
  background: none;
  padding: 0;
  color: ${(p) => p.theme.colors.black};

  i {
    position: relative;
    width: auto;
    right: auto;
    margin: 0.2rem 0.5rem 0 0;
    font-size: 1.8rem;
  }

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const CloseMobileFilterButton = styled(Button)`
  position: absolute;
  top: 3rem;
  right: 2rem;
  width: auto;
  height: auto;
  padding: 0;
  background: none;
  font-size: 2rem;
  color: ${(p) => p.theme.colors.black};
`

const Icon = styled.div`
  padding: 0 0.5rem 0 0rem;
`

const TotalProducts = styled.p`
  text-align: center;
`

const NoResults = styled.p`
  text-align: center;
`

const CollectionGrid = styled.div`
  margin-top: 3.75rem;
`

const SectionTitle = styled.div`
  text-align: left;
  font-size: 16px;
  border-left: 0.5rem solid ${(p) => p.theme.colors.primary};
  font-weight: bold;
  margin: 0 0 1rem 1rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.black};
  padding: 0 0.75rem;
  ${MEDIA_MIN_LARGE} {
    font-size: 30px;
  }
`

const FilterGridProducts = ({
  pageSlug,
  pageTitle,
  collectionProducts,
  collections,
  gender,
  sectionTitle,
  bannerImg,
  bannerText,
  widgetTitle,
  hasImage
}) => {
  const { t } = useTranslation("translations")
  const { getStocks, currentStore } = useContext(BrinkContext)
  const data = useStaticQuery(getProducts).products.nodes
  const [focusedMenu, setFocusedMenu] = useState(null)
  const [products, setProducts] = useState([])
  const [openMobileFilter, setOpenMobileFilter] = useState(false)
  const [currentStock, setCurrentStock] = useState(null)
  const initialProducts =
    collectionProducts
      ?.map((p) => p.siblings)
      .flat()
      .concat(collectionProducts) ?? data

  useMemo(
    async () =>
      await fetchStock(initialProducts, getStocks).then((products) => {
        setCurrentStock(products)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    const products = collectionProducts
      ?.map((cp) => initialProducts.find((ip) => ip._id === cp._id && cp))
      .map((product) => ({
        ...product,
        variants: product.variants.filter((variant) => variant.active)
      }))
    if (data && initialProducts && collectionProducts)
      events.viewCollection(products, currentStore, pageTitle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [genderFilter, setGenderFilter] = useState({
    default: ["dudes", "ladies", "kids"],
    current: ["dudes", "ladies", "kids"],
    state: gender ? [gender] : []
  })

  const [categoryFilter, setCategoryFilter] = useState({
    default: [],
    current: [],
    state: []
  })

  const [colorFilter, setColorFilter] = useState({
    default: [],
    current: [],
    state: []
  })

  const [sizeFilter, setSizeFilter] = useState({
    default: [],
    current: [],
    state: []
  })

  const productsSimple = initialProducts
    .filter((p) => p.active)
    .map((p) => ({
      id: p._id,
      fa: {
        category: p.category.title.toLowerCase(),
        color: p.variants[0].color,
        defaultSizes: p.variants.map((v) => v.size),
        gender: Object.keys(p.gender).filter((key) => p.gender[key])[0],
        size: p.variants.flatMap((v) => {
          return currentStock?.find((p) => p.productId === v._id)?.stock > 0
            ? v.size
            : []
        })
      },
      ...p
    }))

  const buildDefaultFilter = (products) => {
    const filter = { category: [], size: [], color: [] }

    products.forEach((p) => {
      filter.category.push(p.fa.category)
      filter.size.push(p.fa.defaultSizes)
      filter.color.push(p.fa.color)
    })

    setCategoryFilter((prev) => ({
      ...prev,
      default: Array.from(new Set(filter.category))
    }))

    setColorFilter((prev) => ({
      ...prev,
      default: Array.from(new Set(filter.color))
    }))

    setSizeFilter((prev) => ({
      ...prev,
      default: Array.from(new Set(filter.size.flat(2)))
    }))
  }

  const updateCategoryFilter = (products, currentStock) => {
    const category = products
      .filter(
        (p) =>
          genderFilter.state.length === 0 ||
          genderFilter.state.includes(p.fa.gender)
      )
      .filter(
        (p) =>
          colorFilter.state.length === 0 ||
          colorFilter.state.includes(p.fa.color)
      )
      .filter(
        (p) =>
          sizeFilter.state.length === 0 ||
          sizeFilter.state.some((r) => p.fa.size.includes(r))
      )
      .filter((p) =>
        p.variants.some(
          (v) => currentStock?.find((p) => p.productId === v._id)?.stock > 0
        )
      )
      .map((p) => p.fa.category)

    setCategoryFilter((prev) => ({
      ...prev,
      current: Array.from(new Set(category))
    }))
  }

  const updateColorFilter = (products, currentStock) => {
    const color = products
      .filter(
        (p) =>
          genderFilter.state.length === 0 ||
          genderFilter.state.includes(p.fa.gender)
      )
      .filter(
        (p) =>
          categoryFilter.state.length === 0 ||
          categoryFilter.state.includes(p.fa.category)
      )
      .filter(
        (p) =>
          sizeFilter.state.length === 0 ||
          sizeFilter.state.some((r) => p.fa.size.includes(r))
      )
      .filter((p) =>
        p.variants.some(
          (v) => currentStock?.find((p) => p.productId === v._id)?.stock > 0
        )
      )
      .map((p) => p.fa.color)

    setColorFilter((prev) => ({
      ...prev,
      current: Array.from(new Set(color))
    }))
  }

  const updateSizeFilter = (products) => {
    const size = products
      .filter(
        (p) =>
          genderFilter.state.length === 0 ||
          genderFilter.state.includes(p.fa.gender)
      )
      .filter(
        (p) =>
          categoryFilter.state.length === 0 ||
          categoryFilter.state.includes(p.fa.category)
      )
      .filter(
        (p) =>
          colorFilter.state.length === 0 ||
          colorFilter.state.some((r) => p.fa.color.includes(r))
      )
      .map((p) => p.fa.size)

    setSizeFilter((prev) => ({
      ...prev,
      current: Array.from(new Set(size.flat(2)))
    }))
  }

  const filterProducts = (currentStock) => {
    const results = productsSimple
      .filter(
        (p) =>
          genderFilter.state.length === 0 ||
          genderFilter.state.includes(p.fa.gender)
      )
      .filter(
        (p) =>
          categoryFilter.state.length === 0 ||
          categoryFilter.state.includes(p.fa.category)
      )
      .filter(
        (p) =>
          colorFilter.state.length === 0 ||
          colorFilter.state.includes(p.fa.color)
      )
      .filter(
        (p) =>
          sizeFilter.state.length === 0 ||
          sizeFilter.state.some((r) => p.fa.size.includes(r))
      )
      .filter((p) =>
        p.variants.some(
          (v) => currentStock?.find((p) => p.productId === v._id)?.stock > 0
        )
      )

    return Object.values(
      results.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    )
  }

  const clearCurrentFilters = () => {
    if (!pageSlug) {
      setGenderFilter((prev) => ({
        ...prev,
        state: []
      }))
    }
    setCategoryFilter((prev) => ({
      ...prev,
      state: []
    }))
    setColorFilter((prev) => ({
      ...prev,
      state: []
    }))
    setSizeFilter((prev) => ({
      ...prev,
      state: []
    }))
  }

  useEffect(() => {
    buildDefaultFilter(productsSimple)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStock])

  useEffect(() => {
    updateCategoryFilter(productsSimple, currentStock)
    updateColorFilter(productsSimple, currentStock)
    updateSizeFilter(productsSimple, currentStock)
    setProducts(filterProducts(currentStock))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderFilter.state, currentStock])

  useEffect(() => {
    updateColorFilter(productsSimple, currentStock)
    updateSizeFilter(productsSimple, currentStock)
    setProducts(filterProducts(currentStock))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter.state, currentStock])

  useEffect(() => {
    updateCategoryFilter(productsSimple, currentStock)
    updateSizeFilter(productsSimple, currentStock)
    setProducts(filterProducts(currentStock))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorFilter.state, currentStock])

  useEffect(() => {
    updateCategoryFilter(productsSimple, currentStock)
    updateColorFilter(productsSimple, currentStock)
    setProducts(filterProducts(currentStock))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeFilter.state, currentStock])

  return (
    <div>
      <Container>
        {openMobileFilter && (
          <Helmet>
            <html className="disableScroll" lang="en" />
            <body className="disableScroll" lang="en" />
          </Helmet>
        )}
        <FilterContainer open={openMobileFilter}>
          <TopBarMobile>
            <div>{t("Filter products")}</div>
            <CloseMobileFilterButton onClick={() => setOpenMobileFilter(false)}>
              <Icon className="fal fa-times" />
            </CloseMobileFilterButton>
          </TopBarMobile>
          <FilterListContainer>
            <FilterList
              filterType={"gender"}
              filterState={genderFilter}
              setFilterState={setGenderFilter}
              focusedMenu={focusedMenu}
              setFocusedMenu={setFocusedMenu}
              pageSlug={pageSlug}
            />
            <FilterList
              filterType={"category"}
              filterState={categoryFilter}
              setFilterState={setCategoryFilter}
              focusedMenu={focusedMenu}
              setFocusedMenu={setFocusedMenu}
              pageSlug={pageSlug}
            />
            <FilterList
              filterType={"color"}
              filterState={colorFilter}
              setFilterState={setColorFilter}
              focusedMenu={focusedMenu}
              setFocusedMenu={setFocusedMenu}
              pageSlug={pageSlug}
            />
            <FilterList
              filterType={"size"}
              filterState={sizeFilter}
              setFilterState={setSizeFilter}
              focusedMenu={focusedMenu}
              setFocusedMenu={setFocusedMenu}
              pageSlug={pageSlug}
            />
          </FilterListContainer>
          <ButtonsContainer>
            <ClearButton
              onClick={() => {
                setFocusedMenu(null)
                clearCurrentFilters()
              }}
            >
              {t("Clear")}
            </ClearButton>{" "}
            <DoneButton
              onClick={() => setOpenMobileFilter(false)}
              hasIcon
              aria-label={t("Filter")}
            >
              {t("Filter")} <i className="fal fa-sliders" />
            </DoneButton>
          </ButtonsContainer>
        </FilterContainer>
        <OpenMobileFilterButton onClick={() => setOpenMobileFilter(true)}>
          <i className="fal fa-sliders" /> {t("Filter")}
        </OpenMobileFilterButton>
      </Container>
      {pageSlug &&
        currentStock &&
        categoryFilter.state.length === 0 &&
        colorFilter.state.length === 0 &&
        sizeFilter.state.length === 0 ? (
        <>
          {/* if new collectionGrid page these components should be mapped with data from new collectionGrid */}
          <CollectionGrid>
            {collections.map(cp =>
              cp._type !== "imageCard" ?
                (<GridWidget
                  widgetImg={bannerImg}
                  widgetTitle={cp.title}
                  hasImage={hasImage}
                  gender={gender}
                  columns="4"
                  products={cp.products.filter(p => p.onlyVip !== true)}
                  currentStock={currentStock}
                  searchCards={false}
                />) : (
                  cp.video ? (
                    <ImageContainer >
                      <Video>
                        <ReactPlayer
                          url={cp.video}
                          playsinline={true}
                          width="100%"
                          height="100%"
                          muted={true}
                          loop={true}
                          controls={false}
                          playing
                        />
                      </Video>
                    </ImageContainer>

                  ) : cp.imageFile &&
                  <ImageContainer>
                    <Image src={cp.imageFile.asset.url} />
                  </ImageContainer>
                ))}
          </CollectionGrid>
          {/*  */}
        </>
      ) : (
        currentStock &&
        (products?.length > 0 ? (
          <>
            <TotalProducts>
              {products.length} {products.length === 1 ? t("item") : t("items")}
            </TotalProducts>
            <ProductGrid
              ladies={
                genderFilter.state.includes("ladies") &&
                !genderFilter.state.includes("dudes")
              }
              searchCards={true}
              columns="4"
              products={products.filter(p => p.onlyVip !== true)}
            />
          </>
        ) : (
          <NoResults>
            {t("There are no available products matching your current filter.")}
          </NoResults>
        ))
      )}
    </div>
  )
}

export default FilterGridProducts

const getProducts = graphql`
  query {
    products: allSanityProduct(filter: { active: { eq: true } }) {
      nodes {
        _id
        title
        active
        video
        category {
          title
        }
        slug {
          current
        }
        displayName {
          en
        }
        mainImage {
          asset {
            gatsbyImageData
          }
        }
        siblings {
          _id
          variants {
            _id
          }
        }
        gender: genderAttributes {
          ladies: femaleActive
          dudes: maleActive
          kids: unisexActive
        }
        genderAttributes {
          femaleUseAsDefault
          maleUseAsDefault
          femaleImages {
            asset {
              gatsbyImageData
            }
          }
          maleImages {
            asset {
              gatsbyImageData
            }
          }
          unisexImages {
            asset {
              gatsbyImageData
            }
          }
        }
        variants {
          _id
          active
          title
          color: customerAttribute_color
          size: customerAttribute_size
          price {
            EUR
            GBP
            NOK
            SEK
            USD
          }
          discount {
            EUR
            GBP
            NOK
            SEK
            USD
          }
          displayName {
            en
          }
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
