import React from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import {
  containerMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../../constants"
import Card from "../product/card/Card"
import SearchCard from "../product/card/SearchCard"
import ReactPlayer from "react-player"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  padding: 0;
  margin-bottom: 5.75rem;
`

const Image = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0.75rem 0px;
  object-fit: cover;

  ${MEDIA_MIN_MEDIUM} {
    padding: 3rem 1rem 0;
  }
`

const Video = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0.75rem 0px;
  object-fit: cover;

  ${MEDIA_MIN_MEDIUM} {
    padding: 3rem 1rem 0;
  }
`
const Products = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.75rem;

  > div {
    padding: 0 0.75rem;
    width: 50%;
  }

  a {
    padding: 1.5rem 0 0;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 0;

    > div {
      padding: 0;
      width: calc(100% / 3);
    }

    a {
      padding: 3rem 1rem 0;
    }
  }

  ${MEDIA_MIN_LARGE} {
    > div {
      padding: 0;
      width: calc(100% / 4);
    }
  }
`

const SectionTitle = styled.div`
  text-align: left;
  font-size: 16px;
  border-left: 0.5rem solid ${(p) => p.theme.colors.primary};
  font-weight: bold;
  margin: 0 0 1rem 1rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.black};
  padding: 0 0.75rem;
  ${MEDIA_MIN_LARGE} {
    font-size: 30px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.$col === 2 ? '100% !important' : '50% !important'};
  padding-bottom: 80% !important;

  video{
    object-fit: cover;
  }

  ${MEDIA_MIN_MEDIUM} {
    width: 33.3% !important;
    padding-bottom: 0 !important;
  }

  ${MEDIA_MIN_LARGE} {
    width: ${props => props.$col === 2 ? '50% !important' : '25% !important'};
    padding-bottom: 0 !important;
  }
`

const GridWidget = ({
  columns,
  products,
  heading,
  gender,
  path,
  searchCards,
  ladies,
  currentStock,
  hasImage,
  widgetTitle,
  widgetImg,
  ...other
}) => {
  const video = undefined

  return (
    <Container {...other}>
      {widgetTitle && <SectionTitle>{widgetTitle}</SectionTitle>}
      <Products>
        {products && (

          products.map((product, index) =>
            searchCards ? (
              <SearchCard
                key={uuidv4()}
                product={product}
                columns={columns}
                path={path}
                ladies={ladies}
              />
            ) : product._type && product._type === "imageCard" ?

              (
                product.video ? (
                  <ImageContainer key={uuidv4()} $col={product.columns} >
                    <Video>
                      <ReactPlayer
                        url={product.video}
                        playsinline={true}
                        width="100%"
                        height="100%"
                        muted={true}
                        loop={true}
                        controls={false}
                        playing
                      />
                    </Video>
                  </ImageContainer>

                ) : product.imageFile &&
                <ImageContainer $col={product.columns} key={uuidv4()}>
                  <Image src={product.imageFile.asset.url} />
                </ImageContainer>
              )

              :
              (
                <Card
                  key={uuidv4()}
                  product={product}
                  columns={columns}
                  path={path}
                  gender={gender}
                  stocks={currentStock}
                  positionInList={index}
                />
              )
          )
        )}
      </Products>
    </Container>
  )
}

export default GridWidget
